import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import SimpleVueValidation from 'simple-vue-validator'

import '@/assets/scss/main.scss'

export default {
  install (Vue) {
    Vue.use(BootstrapVue)
    Vue.use(VueMaterial)
    Vue.use(SimpleVueValidation)
  }
}
