(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("@customer-apps/styleguide"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "@customer-apps/styleguide"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vue"), require("@customer-apps/styleguide")) : factory(root["vue"], root["@customer-apps/styleguide"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_a223__) {
return 