// DEV
export const DEBUG = true
export const PRODUCTION_TIP = false
export const ENV = 'Development'

// AUTH_TYPE
// 1 = MeodId
// 2 = AD
export const AUTH_TYPE = 1

export const API_BASEURL = process.env.VUE_APP_API_BASEURL ? process.env.VUE_APP_API_BASEURL : ''
export const BASEURL = process.env.VUE_APP_BASEURL ? process.env.VUE_APP_BASEURL : ''
export const MEOID_LOGIN = process.env.VUE_APP_MEOID_LOGIN ? process.env.VUE_APP_MEOID_LOGIN : ''
export const MEOID_LOGOUT = process.env.VUE_APP_MEOID_LOGOUT ? process.env.VUE_APP_MEOID_LOGOUT : ''

export const URL_LOGIN = process.env.VUE_APP_URL_LOGIN ? process.env.VUE_APP_URL_LOGIN : ''
export const URL_LOGOUT = process.env.VUE_APP_URL_LOGOUT ? process.env.VUE_APP_URL_LOGOUT : ''
export const URL_DASHBOARD = process.env.VUE_APP_URL_DASHBOARD ? process.env.VUE_APP_URL_DASHBOARD : ''
export const URL_NEW_USER = process.env.VUE_APP_URL_NEW_USER ? process.env.VUE_APP_URL_NEW_USER : ''
export const URL_TERMS = process.env.VUE_APP_URL_TERMS ? process.env.VUE_APP_URL_TERMS : ''
export const URL_SEED = process.env.VUE_APP_URL_SEED ? process.env.VUE_APP_URL_SEED : ''

export const VALIDATE_EMAIL = 'É necessário ser um email válido'
export const VALIDATE_MINLENGHT = 'Mínimo 9 caracteres'
export const VALIDATE_MATCH_PASSWORD = 'Password deve ser repetida'
export const VALIDATE_MAXLENGTH = 'Máximo 6 caracteres'
