import axios from 'axios'
import { store } from '@customer-apps/styleguide'

import * as Constants from '@/constants'

import { asyncLoading } from 'vuejs-loading-plugin'

export let cancelToken = null
export var cancel = () => {
  if (cancelToken) {
    cancelToken.cancel()
  }
}

export const http = (auth, base = Constants.API_BASEURL) => {
  var requester = axios.create({
    baseURL: base
  })

  if (auth) {
    requester.defaults.headers.Authorization = 'Bearer ' + store.state.auth.access
  }

  cancel()

  // Add a request interceptor
  requester.interceptors.request.use(config => {
    cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelToken.token

    return config
  }, error => {
    return Promise.reject(error)
  })

  // Add a response interceptor
  requester.interceptors.response.use((response) => {
    // Do something with response data
    var result = Promise.resolve(response)
    return asyncLoading(result).then().catch()
  }, (error) => {
    switch (error.response.status) {
      case 401:
        setTimeout(() => {
          if (window.location.pathname.startsWith('/apps/alarms')) {
            return {}
          }
        }, 3000)
        store.commit('resetState')
        window.location.pathname = '/'
        return {}
      default:
        var r = Promise.reject(error)
        return asyncLoading(r).then().catch()
    }
  })
  return requester
}

export default {
  http,
  cancel
}
