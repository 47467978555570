<template>
  <div id="app">
    <ae-header :logo="logo" :menu="menu" :menuMobile="menuMobile"></ae-header>
  </div>
</template>

<script>
import { AeHeader, store } from '@customer-apps/styleguide'
import ApiApp from '@/api/services/App.js'
import * as Constants from '@/constants.js'

export default {
  name: 'App',
  components: {
    AeHeader
  },
  data: () => ({
    logo: {
      url: '/dashboard',
      img: Constants.BASEURL + '/img/logo-altice-empresas-h.png',
      alt: 'MEO Empresas'
    },
    apps: [
      {
        url: '/dashboard',
        icon: 'dashboard',
        name: 'Home',
        check: false
      },
      {
        url: '/app/public-information',
        icon: 'analytics',
        name: 'Portal Projetos',
        check: false
      },
      {
        url: '/app/performance-metrics',
        icon: 'speed',
        name: 'Performance Metrics',
        check: false
      },
      {
        url: '/app/service-pack',
        icon: 'gradient',
        name: 'Service Pack',
        check: false
      },
      {
        url: '/app/tickets',
        icon: 'fact_check',
        name: 'Tickets',
        check: false
      },
      {
        url: '/app/reports',
        icon: 'insert_drive_file',
        name: 'Relatórios de Serviço',
        check: false
      },
      {
        name: 'Pedidos e Mensagens',
        url: '/app/orders-and-messages',
        icon: 'mail_outline',
        check: false
      },
      {
        name: 'Documentos',
        url: '/app/documents',
        icon: 'folder_open',
        check: false
      },
      {
        name: 'CA-Catalog',
        url: '/app/ca-catalog',
        icon: 'fact_check',
        check: false
      },
      {
        name: 'Backups',
        url: '/app/backups',
        icon: 'backups',
        check: false
      },
      {
        name: 'Reporting',
        url: '/app/reporting',
        icon: 'reporting',
        check: false
      },
      {
        name: 'Cadastro',
        url: '/app/cadastro',
        icon: 'folder_open',
        check: false
      },
      {
        name: 'Faturação',
        url: '/app/invoicing',
        icon: 'price_check',
        check: false
      }
    ],
    menu: [],
    menuMobile: [],
    url: window.location.pathname
  }),
  methods: {
    isValidMenu () {
      var result = true
      if (window.location.pathname === '/dashboard') {
        result = false
      }
      if (window.location.pathname === '') {
        result = false
      }
      if (window.location.pathname === '/dashboard#') {
        result = false
      }
      if (window.location.pathname === '/account/sign-in') {
        result = false
      }
      if (window.location.pathname === '/account/sign-out') {
        result = false
      }
      if (window.location.pathname === Constants.URL_LOGOUT) {
        result = false
      }
      return result
    },
    isEmptyMenu () {
      var result = false
      if (store.state.auth.access === '') {
        result = true
      }
      if (window.location.pathname === Constants.URL_LOGOUT) {
        result = true
      }
      if (window.location.pathname === '/account/sign-out') {
        result = true
      }
      if (window.location.pathname === '/account/sign-in') {
        result = true
      }
      return result
    }
  },
  created () {
    this.$loading(true)
    if (this.isEmptyMenu()) {
      this.menu = []
      this.$loading(false)
    } else if (this.isValidMenu()) {
      var appTmp = [
        {
          url: '/dashboard',
          icon: 'dashboard',
          name: 'Home'
        }
      ]
      var allApps = []
      var y = 1
      var count = 0
      ApiApp.get().then(response => {
        this.$loading(false)
        var apps = response.data.results
        console.log(apps)
        apps.forEach(item => {
          count++
          this.apps.forEach((myApp, index) => {
            if (item.name === myApp.name) {
              appTmp.push(myApp)
              if (y === 1) {
                allApps.push(appTmp)
                appTmp = []
                y = 0
              } else {
                y++
              }
              if (appTmp.length > 0 && apps.length === count) {
                allApps.push(appTmp)
                appTmp = []
                y = 0
              }
            }
          })
        })
      })
      this.menu = [
        {
          url: '#',
          type: 'text',
          name: 'APPs',
          toggle: false,
          children: [
            {
              url: '#',
              type: 'app',
              apps: allApps
              // apps: [
              //   [
              //     {
              //       url: '/dashboard',
              //       icon: 'dashboard',
              //       name: 'Dashboard'
              //     },
              //     {
              //       url: '/app/public-information',
              //       icon: 'analytics',
              //       name: 'Portal Projetos'
              //     }
              //   ],
              //   [
              //     {
              //       url: '/app/performance-metrics',
              //       icon: 'speed',
              //       name: 'Dashboards'
              //     },
              //     {
              //       url: '/app/service-pack',
              //       icon: 'gradient',
              //       name: 'Service Pack'
              //     }
              //   ],
              //   [
              //     {
              //       url: '/app/tickets',
              //       icon: 'fact_check',
              //       name: 'Tickets'
              //     },
              //     {
              //       url: '/app/reports',
              //       icon: 'insert_drive_file',
              //       name: 'Reports'
              //     }
              //   ]
              // ]
            }
          ]
        },
        {
          url: '#',
          type: 'icon',
          name: 'person',
          toggle: false,
          children: [
            {
              url: Constants.URL_LOGOUT,
              type: 'line',
              name: 'Logout'
            }
          ]
        }
      ]
      this.menuMobile = [
        {
          url: '#',
          type: 'icon',
          name: 'menu',
          toggle: false,
          children: [
            {
              url: '#',
              type: 'app',
              apps: allApps
            },
            {
              url: Constants.URL_LOGOUT,
              type: 'line',
              name: 'Logout'
            }
          ]
        }
      ]
    } else {
      this.menu = [
        {
          url: '#',
          type: 'icon',
          name: 'person',
          toggle: false,
          children: [
            {
              url: Constants.URL_LOGOUT,
              type: 'line',
              name: 'Logout'
            }
          ]
        }
      ]
      this.menuMobile = [
        {
          url: '#',
          type: 'icon',
          name: 'menu',
          toggle: false,
          children: [
            {
              url: Constants.URL_LOGOUT,
              type: 'line',
              name: 'Logout'
            }
          ]
        }
      ]
      this.$loading(false)
    }
  }
}
</script>

<style lang="scss">
#app {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  height: 100%;
}
</style>
